import axios from "axios";
import UseAxios from "../APIs/UseAxios";
import { AuthProvider } from "../Context/AuthContext";
import { jwtDecode } from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";
import logo1 from '../Assets/MundoBarato1.png'
import { Link, Navigate } from "react-router-dom";


function LoginOne (props){

    
  const [uAccess, setUAccess] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userPass, setUserPass] = useState();
  const [userData, setUserData] = useState([]);
  const [kontrl, setKontrl] = useState(localStorage.getItem('kontrol'));

  const IPLoginToken= process.env.REACT_APP_TOKEN;
  const IPPWDResetReq=process.env.REACT_APP_RESETPWD;


    const letsLogin = (a,b) =>{

      if(!userEmail){
            
        toast(`Por favor coloque su correo electrónico!`, {
          draggablePercent: 20, type: "warning", autoClose: 10000, });
      }else  if(userEmail.length<=6){
        console.log(userEmail.length);
        toast(`Por favor coloque su correo electrónico!`, {
          draggablePercent: 20, type: "warning", autoClose: 10000, });
      }else{

        axios.post(IPLoginToken, {email:a,password:b},  {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
            console.log("logged in");
            
            
            localStorage.setItem("authTokens", JSON.stringify(res.data))
            setUAccess(jwtDecode(localStorage.getItem('authTokens')))
            setUserData(jwtDecode(res.data.access).full_name);
            
            
            console.log(jwtDecode(localStorage.getItem('authTokens')));
            
            
            
              
              
        
              toast(`Ingresando...`, {
                draggablePercent: 20, type: "success", autoClose: 1500, });
                setKontrl(1);

                window.location.href = "/";
            
              // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              
                
            
            }).catch(error => {            
                console.log(`Error: ${error.message}`)
                if(error.message==="Network Error"){
                    toast(`Error de conexión`, {
                        draggablePercent: 20, type: "error", autoClose: 10000, });
                }else {
  
                toast(`Error: ${error.message}`, {
                    draggablePercent: 20, type: "error", autoClose: 10000, });}
              })  
  
      }}

      const letsReqPWDReset = () =>{

          if(!userEmail){
            
            toast(`Por favor coloque su correo electrónico!`, {
              draggablePercent: 20, type: "warning", autoClose: 10000, });
          }else  if(userEmail.length<=6){
            console.log(userEmail.length);
            toast(`Por favor coloque su correo electrónico!`, {
              draggablePercent: 20, type: "warning", autoClose: 10000, });
          }else{




            axios.post(IPPWDResetReq, {email:userEmail},  {
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(res => {
              console.log("Solicitud de reseteo de contraseña creada");
              
              
           
              
              
              
              
              
              
                
                
          
                toast(`Solicitud de reseteo de contraseña creada, espere hasta 72 horas para los siguientes pasos via correo electrónico`, {
                  draggablePercent: 20, type: "info", autoClose: 5000, });
                  setKontrl(1);
  
                  
              
                // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                
                  
              
              }).catch(error => {            
                  console.log(`Error: ${error.message}`)
                  if(error.message==="Request failed with status code 404"){
                    toast(`Usuario no encontrado. Favor verifique los datos ingresados.`, {
                        draggablePercent: 20, type: "warning", autoClose: 10000, });
                }else  if(error.message==="Network Error"){
                      toast(`Error de conexión`, {
                          draggablePercent: 20, type: "error", autoClose: 10000, });
                  }else {
    
                  toast(`Error: ${error.message}`, {
                      draggablePercent: 20, type: "error", autoClose: 10000, });}
                })  
    




          }

      }


return (
    <>
  
  <div className="center">
              <div className="center">
              <Link to={"/"}><img src={logo1} width='25%' alt="Logo"></img></Link>
          </div>
            <div className="box" style={{width:`25%`}}>
              <input className="input" placeholder="Correo electrónico" value={userEmail} type="text" onChange={(e)=> {setUserEmail(e.target.value)}}></input>
              <input className="input" placeholder="Contraseña" value={userPass} type="password" onChange={(e)=> setUserPass(e.target.value)}  onKeyDown={(e) => {
        if (e.key === "Enter")
          letsLogin(userEmail,userPass);
        }}></input>
              <button className="button" onClick={()=> letsLogin(userEmail,userPass)}>Ingresar</button>
             
            </div>
            <div><em> ¿Aún no posee una cuenta?</em> <a> <Link to={"/register"}> <strong style={{color:`#0085c9`}}>Registrarse</strong></Link></a></div>
            <div onClick={()=>{letsReqPWDReset()}} ><a><em> <strong style={{color:`#ccccc0`}}>¿Olvidó su contraseña?</strong></em></a> </div>
            <div className="section is-large"></div>
          </div>
    <ToastContainer/>
    </>
)


}

export default LoginOne;