
import Icon from '@mdi/react';
import { mdiAccount,mdiLogout,mdiCogOutline } from '@mdi/js';
import MBlogo1 from '../Assets/MundoBarato1.png';
import flag1 from '../Assets/RDflag.gif'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { prodDataToFilter } from './MainProductList';
import { jwtDecode } from 'jwt-decode';

export let serchbarPRD=[];

function NavBarOne (props){

    const [serchhValue, setSerchhValue] = useState();
    const [products, setProducts] = useState([]);
    let srcedProducts=[];
    let redirectii=0;
    let navigate = useNavigate();
    const handleNavigate = () => navigate('/',{state:{id:1,srcedProductsi:srcedProducts}});

    useEffect(() => {
       serchbarPRD=products;
       if(!props.srch1){}else{
        props.srch1(products);
       }
      }, [products]); 

    

    const Guere = () =>{
        switch (props.where) {
            case "quienesSomos":
                redirectii=1;
                console.log(redirectii);
                return(
                <div className='center' style={{backgroundColor:`#FFF`}}>
                <div class="tabs" >
                    <ul>
                        <li ><Link  to={`/`} > <span className='link'>Inicio</span></Link></li>
                       
                        <li><Link className='link' to={`/politicas`}> <span className='link'>Políticas</span></Link></li>
                       
                        <li className='is-active'> <Link className='link' to={`/quienesSomos`}> <span className='link'>Nosotros</span></Link></li>
                    </ul>
                </div>
            </div>
                )
                break;
                case "carrito":
                    
                    redirectii=1;
                    console.log(redirectii);
                    return(
                    <div className='center'  style={{backgroundColor:`#FFF`}}>
                    <div class="tabs" >
                        <ul>
                      
                            <li ><Link  to={`/`} > <span className='link'>Inicio</span></Link></li>
                           
                            <li><Link className='link' to={`/politicas`}> <span className='link'>Políticas</span></Link></li>
                           
                            <li > <Link className='link' to={`/quienesSomos`}> <span className='link'>Nosotros</span></Link></li>
                        </ul>
                    </div>
                </div>)
                break;
                case "politicas":
                    redirectii=1;
                    console.log("Redirect?:"+redirectii);
                    return(
                    <div className='center'  style={{backgroundColor:`#FFF`}}>
                    <div class="tabs" >
                        <ul>
                            <li ><Link  to={`/`} > <span className='link'>Inicio</span></Link></li>
                           
                            <li className='is-active'><Link className='link' to={`/politicas`}> <span className='link'>Políticas</span></Link></li>
                           
                            <li > <Link className='link' to={`/quienesSomos`}> <span className='link'>Nosotros</span></Link></li>
                        </ul>
                    </div>
                </div>)
                break;
        
            default:
                redirectii=0;
                console.log(redirectii);
                return(
                <div className='center'  style={{backgroundColor:`#FFF`}}>
                <div class="tabs" >
                    <ul>
                       
                        <li className='is-active'><Link  to={`/`} > <span className='link'>Inicio</span></Link></li>
                       
                        <li><Link className='link' to={`/politicas`}> <span className='link'>Políticas</span></Link></li>
                       
                        <li > <Link className='link' to={`/quienesSomos`}> <span className='link'>Nosotros</span></Link></li>
                    </ul>
                </div>
            </div>)
                break;
        }
    }

    const serchBarr = () => {

        const serchh =()=>{
          console.log("Enter pressed, value: "+serchhValue);
          let srchText=serchhValue.toUpperCase();
  
          prodDataToFilter.map((p)=> {
          
            if(p.descripcion.includes(srchText)){
              console.log(p.descripcion);
              srcedProducts.push(p);
              
              
              
              
            }}
          
          )
  
          setProducts(srcedProducts);
  
        }
  
        return(
            <div class="control has-icons-left has-icons-right" style={{width:`100%`}} >
          <input  value={serchhValue} onChange={(e)=>setSerchhValue(e.target.value)} onKeyDown={(e) => {
          if (e.key === "Enter"){if(redirectii===1){
            handleNavigate()
          }else{ serchh()}}
          }}  className="input is-small" type='search' list='data3' placeholder="Búsqueda Mundo Barato"  /> 
          <span class="icon is-small is-right"> <i class="fas fa-search"></i></span>
          <span class="icon is-small is-left"><i class="fas fa-search"></i></span>
        
                                            
                                            
                                            
        </div>
        )
       }



    return(
        <div style={{backgroundColor:`#FFF`}}>
              
          
            <div className='level' >
            
                <div className='level-left'><Link to={`/`}> <img className='image' width="104" src={MBlogo1} style={{marginLeft:`5%`}}  /></Link>
                   
                    
                
                    
                </div>
                
                    
                   {props.where==="main"? serchBarr(): " "}
                
                                                    
                                                    
                                                    
                
                        
              
                <div class="level-right ">
                <Link to={`/carrito`} style={{marginRight:`0.5rem`}}>{props.func1}</Link>
                {localStorage.getItem('authTokens')? <>
                   <strong style={{fontSize:`0.6rem`,color:`#0085c9`}}>
                   {localStorage.getItem('authTokens')? <>{jwtDecode(localStorage.getItem('authTokens')).bio==="1"? <Link to={"/control"}><Icon path={mdiCogOutline} size={1} /></Link> :"" }</> :"" } 
                    {jwtDecode(localStorage.getItem('authTokens')).username}
                    </strong>
                    <button className="button is-light is-small" onClick={()=>{ localStorage.removeItem("authTokens");window.location.reload(false);}}><Icon path={mdiLogout} size={1} /></button>
                    </>
                     :
                
                    
                        <>
                        <Link to={`/login`} style={{marginRight:`0.5rem`}}><i class="fas fa-user"></i></Link>
                        </>
                    
                }
                
                
                
                
                    <div style={{display:`none`}}>
                    <label class="label">Ingresar   </label>
                            <div class="control has-icons-left has-icons-right">
                                <input class="input is-small is-info" type="text" placeholder="Usuario" />
                                <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                                </span>
                       
                             </div>
                    <div class="control has-icons-left has-icons-right">
                        <input class="input is-small is-info" type="text" placeholder="Contraseña" />
                        <span class="icon is-small is-left">
                        <i class="fas fa-hashtag"></i>
                        </span>
                       
                    </div></div>
                   
                </div>
              
                </div>
              
          
          
          
        </div>
    )

}

export default NavBarOne;