import { useCallback, useEffect, useState } from "react";
import HistoricoDescuentosFunc from "../FunctionalComponents/ControlComponents/HistoricoDescuentos";
import DescuentosListFunc from "../FunctionalComponents/ControlComponents/DescuentosList";
import ProductListImgControl from "../FunctionalComponents/ControlComponents/ProductListImgControl";
import ImgUploader, { img1, prdi, vDescriptioni, versioni } from "../FunctionalComponents/ControlComponents/ImgUploader";
import AxiosImg from "../APIs/AxiosImg";
import { prodDataToFilter } from "../FunctionalComponents/MainProductList";
import AxiosPatchImgControl from "../APIs/AxiosPatchImgControl";
import { imgExactGeti } from "../APIs/AxiosImgExactGet";
import PreventaList from "../FunctionalComponents/ControlComponents/PreventaList";

import Icon from '@mdi/react';
import { mdiCartPercent, mdiStore, mdiStoreAlert, mdiStoreMarker, mdiCartOutline, mdiImagePlusOutline, mdiImageRefreshOutline, mdiImageMultipleOutline, mdiImageSearchOutline, mdiHistory } from '@mdi/js';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";



export const Img1 = () => ({...ImgUploader("1"),});

function Controli(){



  const [fechaMod, setFechaMod] = useState("");
  const [usuario1, setUsuario1] = useState("");
  const [tipoDescuento, setTipoDescuento] = useState("");
  const [estatus, setEstatus] = useState("");
  const [fechaIni, setFechaIni] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [comentario, setComentario] = useState("");

  const [emages1, setEmages1] = useState([]);

  

  const pyURLimages = process.env.REACT_APP_APIIMG1_URL;
  const IPMainProductsGet= process.env.REACT_APP_APIMAINPRDGET2_URL;
  

  let item = "";
  let imgIDi = "";
  let imgID = "";
  let ordeni = "";

  let cki=[];


  let iniRendertxt = 0;
  let ctrl = 0;




  
  
  

  



  useEffect(() => {
    if (emages1.length < 1) return;
    //imgIDi = imgID.join(",") + ",Q1";
    //console.log(imgIDi);
    console.log(emages1[0]);

 
    if(versioni>0){
      AxiosPatchImgControl(`${IPMainProductsGet}${prdi.id}/`,{
        imgCount: emages1.length+imgExactGeti
        
      });
    }else{
    AxiosPatchImgControl(`${IPMainProductsGet}${prdi.id}/`,{
      imgCount: emages1.length+imgExactGeti,
      imagen: emages1[0]
    });}



    Object.keys(emages1).forEach((image) => {

      ordeni++;

      AxiosImg(pyURLimages, {  
      codigo: prdi.codigo,
      title: prdi.descripcion,
      photo:  emages1[image],
      version: versioni,
      descripcionV:  vDescriptioni,
      orden: ordeni
    });
    });
  }, [emages1]);


  if (iniRendertxt === 0) {
    if (!localStorage.getItem('Ctrl') || localStorage.getItem('Ctrl') == null) {} else {
      ctrl = JSON.parse(localStorage.getItem('Ctrl'));

    }
}
iniRendertxt = 1;



  const filterControl = () => {
    let a=prodDataToFilter;
    let list1 = [];

    if (!a){}else{
      prodDataToFilter.forEach((a, index) => {
 


        list1.push(<option key={index} value={a}>{a.descripcion}</option>);
    });}

    return(

      <>{list1}</>
        
      
      )


  }

  const sendPics = () => {
    ordeni=0;
   
   // imgID = [pickDatei, Usuario, SucName];

    setEmages1(img1);
    
  
};

const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/control', {replace: true}), [navigate]);

const RedirectControl =(prop)=>{
  localStorage.setItem('Ctrl', JSON.stringify(prop));
  handleOnClick();
}

const ControlRender =()=>{


  let a;

  switch (ctrl) {
    case 1:
      return(
        <>
        
        <div className='panel center' >
      <div
        className="button collapse-button panel-heading "
        
        style={{backgroundColor:`#003a5d`, color:`#FFF`}} 
      >Control de Descuentos
                
        </div>
    <div className='panel' >
                <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">From</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control is-expanded has-icons-left">
                <input class="input" type="text" placeholder="Usuario"/>
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </p>
            </div>
            <div class="field">
              <p class="control is-expanded has-icons-left has-icons-right">
                <input class="input is-success" type="email" placeholder="Email" value="alex@smith.com"/>
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right">
                  <i class="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label"></div>
          <div class="field-body">
            <div class="field is-expanded">
              <div class="field has-addons">
                <p class="control">
                  <a class="button is-static">
                    +44
                  </a>
                </p>
                <p class="control is-expanded">
                  <input class="input" type="tel" placeholder="Your phone number"/>
                </p>
              </div>
              <p class="help">Do not enter the first zero</p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Department</label>
          </div>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <div class="select is-fullwidth">
                  <select>
                    <option>Tipo de descuento</option>
                    <option>Marketing</option>
                    <option>Sales</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label">
            <label class="label">Reemplaza a un descuento activo?</label>
          </div>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <label class="radio">
                  <input type="radio" name="member"/>
                  Yes
                </label>
                <label class="radio">
                  <input type="radio" name="member"/>
                  No
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Subject</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input class="input is-danger" type="text" placeholder="e.g. Partnership opportunity"/>
              </div>
              <p class="help is-danger">
                This field is required
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Question</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <textarea class="textarea" placeholder="Motivo o comentario"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label">
            {// Left empty for spacing 
            }
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <button class="button is-primary">
                  Realizar cambios
                </button>
              </div>
            </div>
          </div>
        </div>

        </div></div>
        
        </>
      )
      
      break;
    case 2:
      return(
        <>
         <div className='panel center' >
                <div
                  className="button collapse-button panel-heading "
                 
                  style={{backgroundColor:`#003a5d`, color:`#FFF`}} 
                >Histórico Descuentos
                         
                  </div>
                <div className='table-container ' style={{width:`80%`}}>
                <div className='table is-hoverable is-narrow is-scrollable'>
                  <tr>
                    <th>ID_Histórico</th>
                    <th>Fecha_Cambio</th>
                    <th>Usuario</th>
                    <th>Tipo</th>
                    <th>Porcentaje</th>
                    <th>Fecha_Ini</th>
                    <th>Fecha_Fin</th>
                    <th>Comentario</th>                    
                  </tr>
                  <HistoricoDescuentosFunc />
                  
                  </div></div>
                  </div>
        
        </>
      )
      break;

    case 3:
      return(
        <>
         <div className='panel center' >
      <div
        className="button collapse-button panel-heading "
        
        style={{backgroundColor:`#003a5d`, color:`#FFF`}} 
      >Descuentos Activos
                
        </div>
      <div className='table-container ' style={{width:`80%`}}>
      <div className='table is-hoverable is-narrow is-scrollable'>
        <tr>
          <th>ID_Descuento</th>
          <th>Código</th>
          <th>Descripción</th>
          <th>Tipo</th>
          <th>Porcentaje</th>
          <th>Estatus</th>
                           
        </tr>
        <DescuentosListFunc />
        
        </div></div>
        </div>
        <br/><br/><br/>
        <div className='panel center' >
                <div
                  className="button collapse-button panel-heading "
                 
                  style={{backgroundColor:`#003a5d`, color:`#FFF`}} 
                >Histórico Descuentos
                         
                  </div>
                <div className='table-container ' style={{width:`80%`}}>
                <div className='table is-hoverable is-narrow is-scrollable'>
                  <tr>
                    <th>ID_Histórico</th>
                    <th>Fecha_Cambio</th>
                    <th>Usuario</th>
                    <th>Tipo</th>
                    <th>Porcentaje</th>
                    <th>Fecha_Ini</th>
                    <th>Fecha_Fin</th>
                    <th>Comentario</th>                    
                  </tr>
                  <HistoricoDescuentosFunc />
                  
                  </div></div>
                  </div>

       

        <br/><br/><br/>
        
        </>
      )
      break;
    case 4:
      return (

        <>


        <Img1 />
        <div className="button" onClick={sendPics} style={{marginTop:'-6.5%',marginLeft:'20%'}}>Confirmar</div>

        
        <br/><br/><br/>


        <div className='panel center ' >
      <div
        className="button collapse-button panel-heading "
        style={{backgroundColor:`#003a5d`, color:`#FFF`}} 
      
      >Productos
                
        </div>
      <div className='table-container center' >
      <div className='table is-hoverable is-narrow is-scrollable'>
        <tr>
          <th>Código</th>
          <th>Descripción</th>
          <th>Precio</th>
          <th>Imágenes</th>
          
                           
        </tr>
        <ProductListImgControl checki={cki}/>
        
        </div></div>
        </div>
        <br/><br/><br/>

        </>
      )
      break;
    case 5:
      return(
        <>
          
 
        <div className='panel center ' >
      <div
        className="button collapse-button panel-heading "
        style={{backgroundColor:`#003a5d`, color:`#FFF`}} 
      
      >Ventas
                
        </div>
      <div className='table-container center' >
      <div className='table is-hoverable is-narrow is-scrollable'>
        <tr>
          <th>Código</th>
          <th>Descripción</th>
          <th>Precio</th>
          <th>Imágenes</th>
          
                           
        </tr>
        <PreventaList/>
        
        </div></div>
        </div>


        
        </>
      )
  
    default:
      break;
  }

}


 



    return(<>
     <br/><br/><br/>
     <div style={{display:`block`}}>
    <h1 class="title is-1"  style={{color:`#003a5d`}} >Control de ventas, imágenes y descuentos</h1>
    <div class="tabs"><ul></ul></div>
        <br/><br/>
        
<div className="grid container columns is-centered" style={{width:`50%`}}>
        
        <div className="box cell" style={{width:`50%`}} data-toggle="tooltip" data-placement="top" title="Ventas" onClick={()=>RedirectControl(5)}>
        <Icon path={mdiStoreAlert}  />
        </div>

        <div className="box cell" style={{width:`50%`}} data-toggle="tooltip" data-placement="top" title="Descuentos" onClick={()=>RedirectControl(1)}>
        <Icon path={mdiCartPercent} />
        </div>
       
       
        <div className="box cell" style={{width:`50%`}} data-toggle="tooltip" data-placement="top" title="Histórico descuentos" onClick={()=>RedirectControl(3)}>
        <Icon path={mdiHistory }  />
        </div>
        <div className="box cell" style={{width:`50%`, height:`90%`}} data-toggle="tooltip" data-placement="top" title="Imágenes" onClick={()=>RedirectControl(4)}>
        <Icon path={mdiImagePlusOutline}  />
        </div>
        
        
        </div>


<br/><br/><br/>

   {localStorage.getItem('authTokens')? <>{jwtDecode(localStorage.getItem('authTokens')).bio==="1"? <ControlRender /> : window.location.href = "/"}</> : window.location.href = "/" } 


</div>


       

  </>

    )
}

export default Controli;